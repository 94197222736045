<template>
	<div class="page">
		<webHeader :currentIndex="currentIndex"></webHeader>
		<div class="centerMain">
			<navLeft :navList="navList" @exchange="exchange" :title="$t('webTitle.helpCenter')"></navLeft>
			<div class="mainPage">
				<!--暂无记录-->
				<van-empty v-if="!isDetail&&list.length==0" :description="$t('common.nomore')" />
				<!--页面主要内容-->
				<div class="listWrap" v-if="!isDetail">
					<div class="card" v-for="(item,index) in list" :key="item.id" @click="goDetail(item)">
						<!-- <div class="imgArea">
							<img class="image" :src="item.image"/>
						</div> -->
						<div class="info">
							<div class="title">{{item['title_'+lang]}}</div>
							<div class="desc" v-if="item.desc">{{item.desc}}</div>
						</div>
					</div>
				</div>
				<!--详情页-->
				<div class="detailWrap"  v-if="isDetail">
					<div class="title">
						<div class="nav" @click="back()">{{$t('webTitle.back')}}</div>
						<div class="titText">{{detail['title_'+lang]}}</div>
					</div>
					<div class="content">
						<div v-html="detail['content_'+lang]"></div>
					</div>
				</div>
				
			</div>
		</div>
		<webFooter></webFooter>
	</div>
</template>

<script>
	import webHeader from "@/components/web/webHeader.vue";
	import navLeft from "@/components/web/navLeft.vue";
	import webFooter from "@/components/web/webFooter.vue";
	export default {
	    components: { webHeader, navLeft, webFooter },
	    data() {
			return{
				currentIndex:4,
				navList:[
					{
						icon:'',
						name:'Hot',
						path:''
					},
				],
				news: {},
				tabList: [],
				list:[],
				detail:{},
				isDetail:false,
				lang:localStorage.getItem('lang')
			}
		},
		created() {
			this.getNewsCate();
			
		},
		methods:{
			async getNewsCate() {
				let that = this;
			  const { data } = await this.$http.get("/home/home/coursetype");
			  if (data) {
			    if (data.code === 200) {
			      this.tabList = data.data;
				  this.tabList.forEach((item)=>{
					  item.path="";
					  item.icon= "";
					 
					  item.name = item['name_'+that.lang]
					  if(item.children){
						  item.children.map((it)=>{
							  it.path="";
							  it.icon="";
							  it.name = it['name_'+that.lang]
						  })
					  }
				  })
				  console.log("this.tabList",this.tabList)
				  this.navList = this.tabList;
			      this.getzxlist(this.tabList[0].id);
			    }
			  }
			},
			async getzxlist(id) {
			  const { data } = await this.$http.get("home/home/courselist/type/"+id,{});
			  if (data) {
			    if (data.code === 200) {
				  this.list =[];
			      this.list = data.data;
			    }
			  }
			},
			goDetail(item){
				this.detail = item;
				this.isDetail = true;
			},
			back(){
				this.isDetail = false;
			},
			exchange(data){
				if(data.ind){
					this.getzxlist(this.tabList[data.index][data.ind].id)
				}else{
					this.getzxlist(this.tabList[data.index].id)
				}
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.page{ background-color: #141416; min-height: 100vh;
		.centerMain{ padding:20px 0; width:1200px; margin:0 auto; display: flex; justify-content: space-between;
			.mainPage{ min-height: calc(100vh - 80px);background-color: #18191d; color:#fff; width:1000px; text-align: left;}
		}
	}
	.listWrap{ padding:20px;
		.card{ overflow: hidden; padding:20px; cursor: pointer;
			.imgArea{ float: left; margin-right: 20px;
				.image{border-radius: 10px;width: 145px;height: 80px;margin-right: 5px;}
			}
			.info{ 
				.title{ line-height: 36px; white-space: nowrap; font-size: 16px; font-weight: 600;}
				.desc{ line-height: 24px; font-size: 14px;}
			}
			&:hover{ background-color:#141416;}
		}
	}
	.detailWrap{ padding:20px;
		.title{ padding-bottom: 10px; border-bottom:1px solid #ccc;
			.nav{ cursor: pointer; display: inline-block; padding:5px 10px; border:1px solid #fff; border-radius: 5px; margin-right: 10px;}
			.titText{ display: inline-block; font-size: 18px; font-weight: 600;}
		}
		.content{ padding:20px 0;
			
		}
	}
</style>